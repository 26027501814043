<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        type="pagetop"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        setHeight="500px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            dense
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'gas-leak-detection-alram-tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data() {
    return {
      tab: 'gasLeakDetectionAlarmInfo',
      tabItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'gasLeakDetectionAlarmInfo', icon: 'info', label: '기본정보', component: () => import(`${'./gasLeakDetectionAlarmInfo.vue'}`) }, // 가스누출감지 경보기 설치계획
        { name: 'gasLeakDetectionAlarmPid', icon: 'tab', label: '도면', component: () => import(`${'./gasLeakDetectionAlarmPid.vue'}`) }, // 가스누출감지 경보기 설치계획도
        { name: 'gasLeakDetectionAlarmDoc', icon: 'assignment', label: '문서', component: () => import(`${'./gasLeakDetectionAlarmDoc.vue'}`) } // 가스누출감지 경보기 설치계획 문서
      ]
    },

  }
};
</script>